<template>
  <div>
    <el-row style="margin-bottom: 20px;">
      <el-col :span="17">
        <el-card style="margin-bottom: 10px;">
          <search-filter
              :searchName="'搜索题单'"
              :keywordOptions="{placeholder:'搜索题单'}"
              :searchOptions="whereOptions.filter(d=>['diff_id' , 'from_id'].indexOf(d.options.prop) > -1)"
              :otherOptions="whereOptions.filter(d=>['diff_id' , 'from_id'].indexOf(d.options.prop) === -1)"
              v-model="where"
              :total="total"
              @search="$refs.table.reset(...arguments)"
              @eleChange="(v)=>{hotRecommendHeight = v ? 220 : 100}"
          ></search-filter>
        </el-card>
        <div class="operate">
          <el-button-group>
            <el-button :disabled="!$isPowers('questListCreate')" type="primary" size="small" @click="$handleRoute('teachingQuestListCreate')">创建题单</el-button>
          </el-button-group>
        </div>
      </el-col>
      <el-col :span="6" :offset="1">
        <hot-recommend :height="hotRecommendHeight" type="questList"></hot-recommend>
      </el-col>
    </el-row>
    <el-card>
      <com-table
          ref="table"
          :data="tableData"
          :columns="columns"
          :total.sync="total"
          :auto-load="false"
          :edit-method="handleEditChange"
      >
        <template slot="action" slot-scope="scope">
          <el-button type="text" size="small" @click="$handleRoute({list_id:scope.row.id},'teachingQuestListInfo')">查看</el-button>
          <el-button type="text" :disabled="!$isPowers('questListEdit')" size="small" @click="$handleRoute({list_id:scope.row.id},'teachingQuestListEdit')">编辑</el-button>
          <el-button type="text" :disabled="!$isPowers('questListCensus')" size="small" @click="$handleRoute({list_id:scope.row.id},'teachingQuestListCensus')">答题统计</el-button>
          <el-button type="text" size="small" @click="$handleRoute({list_id:scope.row.id},'teachingQuestListQuestion')">题目管理</el-button>
          <el-button :disabled="!$isPowers('questListDel')" @click="$handleConfirm('你确定删除此题单吗？','/xapi/question.quest_list/delete',{id:scope.row.id} , ()=>{$refs.table.reset();})" type="text" size="small">删除</el-button>
        </template>
      </com-table>
    </el-card>
  </div>
</template>

<script>
import SearchFilter from "@/components/com/SearchFilter";
import HotRecommend from "@/views/api/com/hotRecommend";
export default {
  name: "QuestList",
  components: {HotRecommend, SearchFilter},
  data(){
    var _this = this;
    return {
      hotRecommendHeight:220,
      where:{},
      whereOptions:[
        { name:'el-form-item' ,
          options:{label:'难度' , prop:'diff_id', type:'radio-group' , data:{ name:'question_list', keys:'diff_id' } , defaultValue :'全部' }
        },
        { name:'el-form-item' ,
          options:{label:'来源' , prop:'from_id', type:'radio-group'  , data:{ name:'question_list', keys:'from_id' } , defaultValue :'全部'}
        },
        { name:'el-form-item' ,
          options:{label:'年份' , prop:'time_str', type:'year' }
        },
        { name:'el-form-item' ,
          options:{label:'地区' , prop:'province_id', type:'select' , data:{ name:'question_list', keys:'province_id' , isAll:false }}
        },
      ],
      columns:[
        {prop:'id',label:'编号',width:'80' , align:'center'},
        {prop:'title',label:'<span><font style="color:red;">来源</font> - <font style="color:green;">创建人</font> - 题单名称</span>',template:(scope)=>{
            // return '<font style="color:red;">'+(scope.row.fromStr || '')+'</font> - <font style="color:green;">'+scope.row.user_name+'</font> - '+this.$jquery("<font>"+scope.row.title+"</font>").text();
            return '<font style="color:red;">'+(scope.row.fromStr || '')+'</font> - <font style="color:green;">'+`${scope.row.user_nickname?scope.row.user_nickname:scope.row.user_name}`+'</font> - '+this.$jquery("<font>"+scope.row.title+"</font>").text();
          },minWidth:220 , align:'left'},
        {prop:'time_str',label:'时间',width:'120' , align:'center'},
        {prop:'diffStr',label:'难度',width:'120' , align:'center'},
        {prop:'create_date',label:'创建时间',width:'160' , align:'center'},
        {prop:'recommend',label:'是否推荐',width:'110' , slot:'switch' , align:'center',options:{activeValue:1,inactiveValue:0} ,callback(props){props.disabled = !_this.$isPowers('questListEdit')}},
        {prop:'valid',label:'是否有效',width:'110' , slot:'switch' , align:'center' ,callback(props){props.disabled = !_this.$isPowers('questListEdit')}},
        {fixed:'right',label:'操作',width:'240' , slot:'action' , align:'center'},
      ],
      total:0,
      tableData: {
        // url:'/xapi/question.quest_list/myList'
        url:'/xapi/question.question/myList'
      }
    }
  },
  methods: {
    handleEditChange(v , ov , {row , column}){
      this.$http.request({
        url : '/xapi/question.quest_list/updateField',
        params:{
          field:column.prop,
          id:row.id,
          value:v
        }
      }).catch(()=>{
        row[column.prop] = ov;
      })
    }
  },
}
</script>

<style scoped>

</style>